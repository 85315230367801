.contact-card {
  h2 {
    margin-bottom: 30px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 22px;
  }

  a {
    color: #000000;
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
  }

  .name {
    margin-top: 10px;
  }

  .contact-card {
    margin-bottom: 10px;
  }

  .logo {
    height: 36px;
    margin-right: 5px;
  }

  table {
    th {
      font-size: 80%;
      line-height: 24px;
      vertical-align: bottom;
      padding-right: 10px;
    }
  }
}

.bg-gray {
  border-top: 3px solid $theme-color;
  background: #F9F9FA;
  border-bottom: 3px solid $theme-color;
}