/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background-color: #f3f4f6;
  color: #5f687b;
  font-size: 14px;
  position: relative;
}

#footer .footer-top {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 80px 0;
}

#footer .footer-top h3 {
  font-size: 36px;
  font-weight: 700;
  color: #5f687b;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  margin-bottom: 0;
}

#footer .footer-top p {
  font-size: 15;
  font-style: italic;
  margin: 30px 0 0 0;
  padding: 0;
}

#footer .footer-top .footer-newsletter {
  text-align: center;
  font-size: 15px;
  margin-top: 30px;
}

#footer .footer-top .footer-newsletter form {
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  text-align: left;
  border: 1px solid #e4e6ea;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: $theme-color;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: $blue-color;
}

#footer .footer-top .social-links {
  margin-top: 30px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #eaecef;
  color: #5f687b;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: $theme-color;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  //border-top: 1px solid #d9dce2;
  z-index: 2;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;

  display: flex;
}

#footer .copyright {
  float: left;
}

#footer .credits {
  float: right;
  font-size: 13px;
}

@media (max-width: 992px) {
  #footer .footer-bottom {
    flex-direction: column;
  }


  #footer .copyright, #footer .credits {
    float: none;
    -moz-text-align-last: center;
    text-align-last: center;
  }
}
