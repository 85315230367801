@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-100thin-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-100thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-200xlight-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-200xlight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-300light-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-300light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-400regular-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-400regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-500medium-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-500medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-600semibold-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-600semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-700bold-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-700bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/sinkinsans/sinkinsans-800black-webfont.woff2') format('woff2'),
  url('../fonts/sinkinsans/sinkinsans-800black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

$sinkin-sans: "Sinkin Sans", sans-serif;

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Thin.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

$roboto: "Roboto", sans-serif;
