
//$theme-color: #16df7e;
//$link-color: #14c871;
//$blue-color: #206bfb;

$green-color: #B1D34B;
$link-color: #32A18E;
$theme-color: #32A18E;
$blue-color: #4086BA;

$primary-font: $sinkin-sans;
$secondary-font: $roboto;

$primary-text-color: #5A5A5A;
$secondary-text-color: $theme-color;
