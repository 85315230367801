// Default root font-size value in most browsers is 16px and we use that as basis for all calculations.
$browser-context: 16px;

// Returns em value from px
@function em($pixels, $context: $browser-context) {
  @return rel($pixels, $context) * 1em;
}

// Returns rem value from px
@function rem($pixels, $context: $browser-context) {
  @return rel($pixels, $context) * 1rem;
}

@function rel($pixels, $context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context;
}

$hs: h1, h2, h3, h4, h5, h6;