.pricing {
  font-family: $roboto;

  .box {
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    height: 100%;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -4px;
      height: 20px;
      border-top: 5px solid black;
      border-radius: 10px 10px 0 0;
      background: black;
      z-index: -1;
    }

    .box-inner {
      padding: 30px 20px 30px;
      margin-bottom: 55px;
      background: #fff;
      border-radius: 10px;

      @include media-breakpoint-up(sm) {
        padding: 30px 40px 30px;
      }
    }

    .badge-discount {
      position: absolute;
      right: -15px;
      top: -25px;
      color: white;
      padding: 40px 25px;
      height: 126px;
      width: 126px;
      font-weight: 500;
      border-radius: 50%;
      font-size: 24px;
      transform: rotate(15deg);
      line-height: 1em;
      text-transform: uppercase;
      z-index: 10;

      strong {
        font-size: rem(48);
      }
    }

    .btn-try {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      border-radius: 0 0 10px 10px ;
      font-size: rem(18);
      color: white;
      padding: 25px 0 20px;
      text-transform: uppercase;
      font-weight: 700;

      @include media-breakpoint-up(sm) {
        font-size: rem(22);
      }

      &:hover {
        text-decoration: none;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        height: 20px;
        border-bottom: 10px solid white;
        border-radius: 10px;
        background: white;
      }
    }

    .bx {
      font-weight: bold;
    }

    &.freelancer {
      &:before {
        border-top-color: $green-color;
        background-color: $green-color;
      }

      .bx.bx-check, .monthly {
        color: $green-color;
      }

      .badge-discount, .btn-try {
        background: $green-color;
      }
      .btn-try:hover {
        background: darken($green-color, 10%);
      }
    }

    &.agency {
      &:before {
        border-top-color: $theme-color;
        background-color: $theme-color;
      }

      .bx.bx-check, .monthly{
        color: $theme-color;
      }

      .badge-discount, .btn-try {
        background: $theme-color;
      }
      .btn-try:hover {
        background: darken($theme-color, 10%);
      }
    }

    .image {
      display: none;
      position: absolute;
      top: 25px;
      right: 20px;
      width: 50%;
      object-fit: contain;
      height: 175px;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
      }

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    h3 {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: rem(30);
      margin-right: 100px;
      z-index: 2;
    }

    h4 {
      font-weight: 400;
      margin-bottom: 0;
      font-size: rem(24);
      color: #454040;
      &.na {
        color: #ccc;
      }
    }

    .price {
      margin-bottom: 25px;
      display: inline-block;
      font-family: $roboto;
      z-index: 2;
      min-height: 100px;

      .crossed {
        font-size: rem(24);
        font-weight: 300;
        color: #B8B8B8;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          right: 0;
          border-top: 2px solid #E40000;
          transform: rotate(-10deg);
        }
      }

      small {
        font-weight: 300;
      }

      div {
        color: #585858;
        font-size: rem(48);
        font-weight: 300;
        line-height: 40px;

        sup {
          font-size: 28px;
        }

        .monthly {
          font-weight: 500;
          text-align: right;
          font-size: rem(18);
          display: block;
          line-height: 1em;
        }
      }
    }

    ul {
      padding: 0 0 10px;
      list-style: none;
      color: #454040;
      text-align: left;
      line-height: 24px;

      li {
        padding: 8px 0 0px 30px;
        position: relative;
        font-size: rem(16);
        font-weight: 500;

        @include media-breakpoint-up(sm) {
          font-size: rem(18);
        }

        &:before {
          display: none;
        }
      }

      i {
        color: $green-color;
        font-size: rem(24);
        position: absolute;
        left: 0;
        top: 6px;
      }

      .na {
        color: #ccc;
        i {
          color: #ccc;
        }
        span {
          text-decoration: line-through;
        }
      }
    }
  }


  .pricing-switch {
    display: flex;
    justify-content: center;
    align-items: center;

    .back {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: rem(22);
      font-weight: 600;
      line-height: rem(22);
      padding: 5px 10px;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid $theme-color;
      }
    }

    input, label {
      display: inline-block;
      vertical-align: middle;
      margin: 10px;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      background: #F9F9F9;
      border-radius: 30px;
      box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1) inset;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0px, 0px);
      transition: 0.6s ease transform, 0.6s box-shadow;

      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        border-radius: 50%;
        background-color: $theme-color;
        transition: 0.4s;
      }
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $theme-color;
    }

    input:checked + .slider:before {
      transform: translateX(26px);
    }
  }
}