/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 80vh;
  background: #fff;
  padding-top: 82px;
}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #3e4450;
}

#hero h2 {
  color: #858ea1;
  margin: 10px 0  30px 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: $theme-color;
  box-shadow: 0 8px 28px transparentize($theme-color, .55);
}

#hero .btn-get-started:hover {
  background: $blue-color;
  box-shadow: 0 8px 28px transparentize($blue-color, .55);
}

#hero .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  padding: 8px 35px 8px 25px;
  transition: 0.5s;
  margin-left: 10px;
  color: #5f687b;
  position: relative;
}

#hero .btn-watch-video i {
  color: #949cac;
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 8px;
}

#hero .btn-watch-video:hover i {
  color: $theme-color;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #hero {
    height: auto;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}