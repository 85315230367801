@import "~bootstrap/scss/bootstrap";
@import "font";
@import "dimensions";
@import "variables";
@import "~font-awesome";

@import "eNno/enno";
@import "pricing";
@import "gallery";
@import "contact";
@import "form";
@import "text";

.footer-brand {
  margin-right: 20px;
  img{
    height: 30px;
  }
}

.footer-menu {
  .nav-item {
    margin: 3px 5px;

    > a {
      color: black;
    }
  }
}

.lang-menu {
  a {
    color: black;
  }
}

body {
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  footer {
    margin-top: auto;
  }
}

.signup-button {
  background: $theme-color;
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
  color: #fff !important;

  &:hover {
    box-shadow: 0 8px 28px transparentize($theme-color, .55);
  }
}