
.form-check {
  padding: 0.5em 0;
  padding-left: 0 !important;
}

input[type=checkbox] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 29px;
  height: 29px;
  left: 20px;
  top: 3px;
}

input[type=checkbox]:checked+label:before {
  color: $link-color;
  font-family: 'FontAwesome';
  content: "\f00c";
  line-height: 29px;
  font-size: 140%;
  text-align: center;
}

.form-check-label a {
  font-weight: normal;
}

.form-check-label:before {
  content: '';
  display: inline-block;
  width: 29px;
  height: 29px;
  margin-right: 10px;
  border: #CED4DA 1px solid;
  border-radius: .25rem;
  background: transparent;
  vertical-align: bottom;
}
