.gallery {
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 10px;


  figure {
    display: block;
    border: none;

    > a {
      position: relative;

      & > img {
        width:100%;
        height:100%;
        object-fit: cover;
        overflow: hidden;
      }


      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (2 / 4) * 100%;
      }
      > img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    img {
      background: transparent;
      display: inline-block;
      padding: 0;
      border-radius: 0;
    }

    .thumbnail {
      border: none;
      margin-bottom: 0;
    }

    a {
      display: block;
      color: black !important;
    }

    a:hover {
      figcaption {
        color: black !important;
      }
    }

    figcaption{
      position: relative;
      text-transform: uppercase;
      text-align: left;
      margin: 5px 0 10px 0;
      font-weight: 600;
      padding-left: 30px;

      &:before {
        content: ">";
        display: inline-block;
        position: absolute;
        font-family: $sinkin-sans;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        left: 6px;
        top: 0;
        color: $green-color;
        transform: rotate(-90deg);
      }

    }

  }
}
