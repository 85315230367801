.text {
  color: $primary-text-color;
  font-family: $secondary-font;
  font-size: em(16);

  p,ul > li,ol > li {
    text-align: justify;
  }

  p, ul, ol {
    margin-bottom: 20px;
  }

  blockquote {
    position: relative;
    padding: 20px;
    margin-bottom: 30px;
    font-family: $secondary-font;
    font-size: em(23);
    font-weight: 200;
    line-height: em(23);
    border-radius: 10px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    height: 100%;
    border-bottom: 5px solid $theme-color;

    p {
      margin-bottom: 0;
    }
  }

  a {
    font-weight: inherit;

    > #{$hs} {
      color: $theme-color;
    }
  }

  ol {
    padding-left: 25px;

    li {
      padding: 2px 0;
    }
  }

  ul {
    list-style: none;
    padding-left: 25px;

    li {
      padding: 2px 0;
      position: relative;
    }

    li:before {
      display: inline-block;
      font-family: $primary-font;
      font-size: em(20);
      font-weight: 700;
      line-height: em(14);
      color: $green-color;
      content: '>';
      position: absolute;
      top: 3px;
      left: -20px;
    }
  }

  #{$hs} {
    color: $secondary-text-color;
    font-family: $secondary-font;

    > a {
      font-family: inherit;
    }
  }

  h1 {
    font-size: em(30);
  }

  h2 {
    font-size: em(24);
  }

  h3 {
    font-size: em(20);
  }

  h4 {
    font-size: em(17);
  }

  h5 {
    font-size: em(14);
  }

  .table-responsive {
    border: 0;
  }

  table {
    font-family: $primary-font;
    font-size: em(15);
    font-weight: 500;
    table-layout: fixed;
    width: 100%;
    float: none;

    th, td, tr {
      border: 1px solid #FFFFFF;
      padding: 4px 8px !important;
    }

    th,
    thead td {
      font-family: $secondary-font;
      font-size: em(15);
      font-weight: 600;
      color: #FFFFFF;
      background-color: $theme-color;
      text-transform: uppercase;
    }

    tbody tr:nth-child(2n+1) {
      th {
        background-color: darken($theme-color, 5%);
      }
      td {
        background-color: darken(#FFFFFF, 5%);
      }
    }

    caption {
      font-family: $secondary-font;
      font-size: em(18);
    }
  }

  hr {
    margin: 30px 0;
  }
}
